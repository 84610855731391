import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
    allGraphData: {
        Overall: [],
        byPicker: {}
    },
    loadingGraphData: false,
    loadingProgressValue: 0,
    deliveryStartDate: '',
    deliveryEndDate: '',
    pickersList: {},
    selectedPickersList: {},
    lastYearData: {},
    currentYearData: {},
    earliestStartDate: "",
};

export const DashboardReducer = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setDeliveryStartDate: (state, action) => {
            state.deliveryStartDate = action.payload;
        },
        setDeliveryEndDate: (state, action) => {
            state.deliveryEndDate = action.payload;
        },
        setAllGraphData: (state, action) => {
            let finalData;
            if (Object.keys(action.payload.current_year.pallet_chart).length !== 0) {
                const chartData = action.payload.current_year.pallet_chart.chart_final_result
                const overallChartData = []
                Object.keys(chartData).forEach(person => {
                    chartData[person].forEach(entry => {
                        const existingDateEntry = overallChartData.find(
                            overallEntry => overallEntry.date === entry.date
                        );
                        if (existingDateEntry) {
                            existingDateEntry.count += entry.count;
                        } else {
                            overallChartData.push({ date: entry.date, count: entry.count });
                        }
                        overallChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
                    });
                });
                const overallChartValue = [];
                for (let date = new Date(dayjs(action.payload.current_year.date.start_date)); date <= dayjs(action.payload.current_year.date.end_date); date.setDate(date.getDate() + 1)) {
                    const currentDateStr = dayjs(date).format('YYYY-MM-DD');
                    const dateHaveError = overallChartData.find(entry => entry.date === currentDateStr);
                    overallChartValue.push({ date: currentDateStr, count: dateHaveError?.count ?? 0 });
                }
                const byPicker = action.payload.current_year.pallet_chart.chart_final_result
                const sortedChartData = Object.keys(byPicker).reduce((result, key) => {
                    result[key] = byPicker[key].sort((a, b) => new Date(b.date) - new Date(a.date));
                    return result;
                }, {});
                finalData = {
                    Overall: overallChartValue,
                    byPicker: sortedChartData
                }
            } else {
                finalData = {
                    Overall: [],
                    byPicker: {}
                }
            }
            state.allGraphData = finalData
        },
        setloadingGraphData: (state, action) => {
            state.loadingGraphData = action.payload;
        },
        setLoadingProgressValue: (state, action) => {
            state.loadingProgressValue = action.payload;
        },
        setPickersList: (state, action) => {
            state.pickersList = action.payload;
        },
        setSelectedPickersList: (state, action) => {
            state.selectedPickersList = action.payload;
        },
        setLastYearData: (state, action) => {
            state.lastYearData = action.payload;
        },
        setCurrentYearData: (state, action) => {
            state.currentYearData = action.payload;
        },
        setEarliestStartDate: (state, action) => {
            if (action.payload !== undefined) {
                state.earliestStartDate = action.payload;
            } else {
                state.earliestStartDate = dayjs(new Date()).subtract(29, 'day');
            }
        },
    },
});
export const {
    setloadingGraphData,
    setDeliveryStartDate,
    setDeliveryEndDate,
    setPickersList,
    setSelectedPickersList,
    setLastYearData,
    setCurrentYearData,
    setAllGraphData,
    setEarliestStartDate,
    setLoadingProgressValue,
} = DashboardReducer.actions;
export default DashboardReducer.reducer;
