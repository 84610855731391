import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loadingDockDoorData: false,
    assignRouteData: {},
    doorsList: [],
    dcIPAddress: '',
    isStartRefresh: false,
};

export const DockDoorControlReducer = createSlice({
    name: "dockDoorControl",
    initialState,
    reducers: {
        setAssignRouteData: (state, action) => {
            state.assignRouteData = action.payload;
        },
        setDoorsList: (state, action) => {
            state.doorsList = action.payload;
        },
        setLoadingDockDoorData: (state, action) => {
            state.loadingDockDoorData = action.payload;
        },
        setDCIPAddress: (state, action) => {
            state.dcIPAddress = action.payload;
        },
        setStartDockDoorRefresh: (state, action) => {
            state.isStartRefresh = action.payload;
        },
    },
});
export const {
    setAssignRouteData,
    setDoorsList,
    setLoadingDockDoorData,
    setDCIPAddress,
    setStartDockDoorRefresh,
} = DockDoorControlReducer.actions;
export default DockDoorControlReducer.reducer;
