import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  palletArchiveData: {},
  loadingPalletsArchiveData: false,
  earliestStartDate: {},
  rowsPerPage: "",
};

export const PalletArchiveReducer = createSlice({
  name: "PalletArchive",
  initialState,
  reducers: {
    setPalletArchiveData: (state, action) => {
      state.palletArchiveData = action.payload;
    },
    setLoadingPalletsArchiveData: (state, action) => {
      state.loadingPalletsArchiveData = action.payload;
    },
    setEarliestStartDate: (state, action) => {
      state.earliestStartDate = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
  },
});

export const {
  setPalletArchiveData,
  setLoadingPalletsArchiveData,
  setEarliestStartDate,
  setRowsPerPage,
} = PalletArchiveReducer.actions;

export default PalletArchiveReducer.reducer;
