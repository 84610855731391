import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { setUserData } from "../reducers/authentication/AuthReducer";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const CNETRAL_AUTH_ENDPOINT = `${API_ENDPOINT}/Central`;

export const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    console.log("session", session.accessToken.jwtToken);
    return session.accessToken.jwtToken;
  } catch {
    return null;
  }
};

export const checkAccess = createAsyncThunk(
  "AuthThunk/checkAccess",
  async (data, { dispatch }) => {
    //   console.log("checkAccess:", data);
    const headers = {
      Authorization: await getToken(),
    };
    try {
      let response = await fetch(
        CNETRAL_AUTH_ENDPOINT + "/auth/application/7",
        {
          method: "GET",
          headers: headers,
        }
      );
      console.log("checkAccess API response:", response);
      dispatch(setUserData(response));
      return (response = await response.json());
      // console.log("checkAccess API response:", response);
    } catch (error) {
      console.error("API error:", error);
    }
  }
);
