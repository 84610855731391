import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operationsPerformanceURL: null,
  loadingOperationURL: false,
  pickerDetailURL: null,
  loadingPickerURL: false,
  operationsPerformanceQURL: null,
};

export const WarehouseInsightsReducer = createSlice({
  name: "WarehouseInsights",
  initialState,
  reducers: {
    setOperationsPerformanceURL: (state, action) => {
      state.operationsPerformanceURL = action.payload['embed-url'];
    },
    setPickerDetailURL: (state, action) => {
      state.pickerDetailURL = action.payload['embed-url'];
    },
    setLoadingloadingOperationURL: (state, action) => {
        state.loadingOperationURL = action.payload;
      },
    setLoadingPickerURL: (state, action) => {
        state.loadingPickerURL = action.payload;
      },
    resetEmbedURL: (state, action) => {
        state.pickerDetailURL = null
        state.operationsPerformanceURL = null
        state.operationsPerformanceQURL = null
    },
    setOperationsPerformanceQURL: (state, action) => {
      state.operationsPerformanceQURL = action.payload['embed-url'];
    },
  },
});

export const {
  setOperationsPerformanceURL,
  setPickerDetailURL,
  setLoadingloadingOperationURL,
  setLoadingPickerURL,
  resetEmbedURL,
  setOperationsPerformanceQURL,
} = WarehouseInsightsReducer.actions;

export default WarehouseInsightsReducer.reducer;