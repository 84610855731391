import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Auth } from "aws-amplify";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Button} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // boxShadow: 24,
  outline: 'none',
  p: 4,
};

export default function ShowModal({ type }) {
  const [open, setOpen] = useState(true);
  const handleClose = async () => {
    setOpen(false)
    localStorage.clear();
    await Auth.signOut();
    window.location.replace('/');
    setTimeout(()=>{
      //  window.location.reload();
      let newloc = window.location.origin;
      window.location.href = newloc;
    },1000)
  };

  return (
    <div>
      <Modal open={open}  sx={{ border: 'none' }}>
        <Box sx={style}>
          {/* <Typography variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          <Typography sx={{ mt: 2 }}>
            {"Your user doesn't have permission to this application. Please contact support." }
          </Typography>
          <Box sx={{textAlign:"center",paddingTop:"10px"}}>
            <Button variant="contained"  onClick={handleClose} sx={{width:"100px"}}>OK</Button>
          </Box>
        </Box>


      </Modal>
    </div>
  );
}
