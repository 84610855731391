import React from "react";
import { Routes, Route } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import SideNavDrawer from "./components/navigation/SideNavDrawer";
import {RouteList} from 'duoiotweb';
import RouteDashBoardDetails from "./pages/RouteDashBoardDetails";
import LoadingBar from "./components/Common/LoadingBar";

//import RouteDashBoard from "./pages/RouteDashBoard";
//import AssetDashBoard from "./pages/AssetDashBoard";
//import LiftDashBoard from "./pages/LiftDashBoard";
//import CasesDashBoard from "./pages/CasesDashBoard";

const PalletReview = React.lazy(() => import("./pages/PalletReview"));
const AuditPallet = React.lazy(() =>
  import("./components/palletReview/AuditPallet")
);
const DockDoorStatus = React.lazy(() => import("./pages/DockDoorStatus"));
const DockDoorControl = React.lazy(() => import("./pages/DockDoorControl"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const PalletArchive = React.lazy(() => import("./pages/PalletArchive"));
const TensorInsights = React.lazy(() => import("./pages/TensorInsights"));
const OperationalPerformance = React.lazy(() => import("./pages/OperationalPerformance"));
const PickerDetail = React.lazy(() => import("./pages/PickerDetail"));
const RouteDashBoard = React.lazy(() => import("./pages/RouteDashBoard"));
const AssetDashBoard = React.lazy(() => import("./pages/AssetDashBoard"));
const LiftDashBoard = React.lazy(() => import("./pages/LiftDashBoard"));
const LiftMaintenance = React.lazy(() => import("./pages/LiftMaintenance"));
const CasesDashBoard = React.lazy(() => import("./pages/CasesDashBoard"));

const AddMaintenance = React.lazy(() => import("./pages/LiftAddMaintenance"));


export default function AuthRoutes() {
  return (
    <React.Fragment>
      <SideNavDrawer>
        <React.Suspense fallback={<LoadingBar />}>
          <Routes>
            <Route path="/qcbot"  element={<PalletReview />} />
            <Route path="/auditpallet/:inference_id/:dc_id"  element={<AuditPallet />}/>
            <Route path="/dockdoorstatus"  element={<DockDoorStatus />} />
            <Route path="/dockdoorcontrol"  element={<DockDoorControl />}/>
            <Route path="/dashboard"  element={<Dashboard />} />
            <Route path="/palletarchive"  element={<PalletArchive />} />
            <Route path="/vor-gpt"  element={<TensorInsights />} />
            <Route path="/operational-performance"  element={<OperationalPerformance />} />
            <Route path="/picker-detail"  element={<PickerDetail />} />
            <Route path="/routestatus"  element={<RouteDashBoard />} />
            <Route path="/routedetails"  element={<RouteDashBoardDetails />} />
            <Route path="/assets"  element={<AssetDashBoard />} />
            <Route path="/lifts"  element={<LiftDashBoard />} />
            <Route path="/liftmaintenance"  element={<LiftMaintenance />} />
            <Route path="/addMaintenance" exact element={<AddMaintenance />} />
            <Route path="/casesperhour"  element={<CasesDashBoard />} />

          </Routes>
        </React.Suspense>
      </SideNavDrawer>
    </React.Fragment>
  );
}
