export class Constants {


    static locationId = ""
    static customerId = "";
    static dcId = "";
    static DC_MODE = "edge";
    static DC_MODE_EDGE = "edge"
    static DC_MODE_CLOUD = "cloud"
    static RPC_ADMIN = "RpcAdmin"
    static RPC_READ_ONLY = "RpcReadOnly"
    static PORTRAIT = "portrait";
    static LANDSCAPE = "landscape";
    static isAuditLoaded = false;

    static MISSING = "Missing";
    static EXTRA = "Extra";
    static CAN_NOT_CONFIRM = "Can’t confirm";
    static AUDITED = "Audited";
    static NO_ERROR = "No error";
    static UN_TRAINED = "UNTRAINED";
    static SCREEN_NAME = "";
    static PALLET_REVIEW = "PalletReview";
    static PALLET_ARCHIVE = "PalletArchive";

    static palletArchiveData = []

    static IS_IMAGE_CORRECTION_ENABLED = false;
    static userId = ""

}
