export const SESSION_KEY = "QCBOT_LIST_SESSION";
export const SESSION_KEY_ARCHIEVE = "QCBOT_LIST_SESSION_ARCHIEVE";

export const getInferenceItemStatus = (data) => {
  if (data?.is_un_trained_sku) {
    if (data?.is_qc_bot_audited_sku) {
      return "Audited";
    } else {
      return "UNTRAINED";
    }
  }

  let status = data?.status;
  if (status !== "MATCHED") {
    if (data?.actual_quantity > data?.expected_quantity) status = "Extra";
    else status = "Missing";
  } else status = "No error";

  if (data?.is_qc_bot_audited_sku) status = "Audited";
  else if (data?.is_unverifiable_sku) status = "Can’t confirm";

  return status;
};

export const isMobile = ()=> {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  let mobile = regex.test(navigator.userAgent) ? (window.orientation ==0 ? true: false):false;
  /*if(!mobile){
    let screenWidth = window.screen.width;
    let screenHeight = window.screen.height;
    if((screenWidth <= 768 && screenHeight <= 1024)){ //iPad mini //||
      mobile = true;
      return mobile;
    }else if((screenWidth <= 820 && screenHeight <= 1180)){ //iPad Air // ||
      mobile = true;
      return mobile;
    }else if((screenWidth <= 1024 && screenHeight <= 1366)){ //iPad Pro // ||
      mobile = true;
      return mobile;
    }else if((screenWidth <= 912 && screenHeight <= 1366)){ //Surface Pro // ||
      mobile = true;
      return mobile;
    }else if((screenWidth <= 540 && screenHeight <= 720)){ //Surface Duo //||
      mobile = true;
      return mobile;
    }else if((screenWidth === 2160 && screenHeight === 1620)){ //Apple iPad 10.2
      mobile = true;
      return mobile;
    }

  }else if(!mobile){
    mobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
  }else if(!mobile){
    let bodyElement = document.getElementsByTagName('body')[0];
    mobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
  }*/

  return mobile
}
const iOS1to12quirk = ()=> {
  var audio = new Audio(); // temporary Audio object
  audio.volume = 0.5; // has no effect on iOS <= 12
  return audio.volume === 1;
};
export const isTabletDevice = ()=> {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);
  const iOS13_iPad = (navigator.platform.includes("Mac") && navigator.maxTouchPoints > 1);
  const isIOS = !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());

  let mobile = regex.test(navigator.userAgent);//? (window.orientation ==0 ? true: false):false;

  if(!mobile){
    return  isIOS;
  }
  return mobile;

  /*if(!mobile){
    let screenWidth = window.screen.width;
    let screenHeight = window.screen.height;
    if(window.matchMedia("(orientation: portrait)").matches) {
      if ((screenWidth <= 768 && screenHeight <= 1024)) { //iPad mini //||
        mobile = true;
        return mobile;
      } else if ((screenWidth <= 820 && screenHeight <= 1180)) { //iPad Air // ||
        mobile = true;
        return mobile;
      } else if ((screenWidth <= 1024 && screenHeight <= 1366)) { //iPad Pro // ||
        mobile = true;
        return mobile;
      } else if ((screenWidth <= 912 && screenHeight <= 1368)) { //Surface Pro // ||
        mobile = true;
        return mobile;
      } else if ((screenWidth <= 540 && screenHeight <= 720)) { //Surface Duo //||
        mobile = true;
        return mobile;
      } else if ((screenWidth === 1620 && screenHeight === 2160 )) { //Apple iPad 10.2
        mobile = true;
        return mobile;
      }
    }else if(window.matchMedia("(orientation: landscape)").matches){
      if((screenWidth <= 1024 && screenHeight <= 768)){ //iPad mini //||
        mobile = true;
        return mobile;
      }else if((screenWidth <= 750 && screenHeight <= 1150)){ //iPad mini //||
        mobile = true;
        return mobile;
      }else if((screenWidth === 2160 && screenHeight === 1620)){ //Apple iPad 10.2
        mobile = true;
        return mobile;
      }else if((screenWidth <= 1180 && screenHeight <= 820)){ //iPad mini //||
        mobile = true;
        return mobile;
      }else if((screenWidth <= 1368 && screenHeight <= 1024)){ //iPad mini //||
        mobile = true;
        return mobile;
      }
    }

  }*/
  /*if(!mobile){
    mobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
  }else*/



}
