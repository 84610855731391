import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Button,
  Grid,
  Link,
  Radio,
  InputAdornment,
  IconButton,
  Alert
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Auth } from "aws-amplify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AppLogo, RehrigLogo } from "../../assets";
import { validatePasswordRequirement } from "./auth.config";
import {Constants} from "../../utils/Constants";

export default function SignIn() {
  const [signInError, setSignInError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [forgotPasswordSendCodeError, setForgotPasswordSendCodeError] = useState("");
  const [forgotPasswordSendCodeConfirmError, setForgotPasswordSendCodeConfirmError] = useState("");
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newUserAuthToken, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwChar, setPwChar] = useState("");
  const [pwCap, setPwCap] = useState("");
  const [pwNum, setPwNum] = useState("");
  const [pwsc, setPwsc] = useState("");
  const [pwll, setPwll] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [signingIn, setSigningIn] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [codeSent, setCodeSent] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [forgotSuccessMsg, setForgotSuccessMsg] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [sendCodeValue, setSendCodeValue] = useState("");

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    if (pwChar && pwCap && pwNum && email && pwsc && pwll) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [pwChar, pwCap, pwNum, email, pwsc, pwll]);

  const handleOnBlur = (e) => {
    if (!isValidEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  function handleFormInputChange(value, setState, id) {
    setForgotPasswordSendCodeError("");
    setForgotPasswordSendCodeConfirmError("");
    setState(value);
    setSignInError("");

    if (id === "password")
      validatePasswordRequirement(
        value,
        setPwChar,
        setPwNum,
        setPwCap,
        setPwsc,
        setPwll
      );
  }

  function isValidEmail(email) {
    return email.trim().length !== 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setSigningIn(true);
    const data = new FormData(event.currentTarget);
    try {
      const user = await Auth.signIn(data.get("email"), data.get("password"));
      if(user && user.attributes){
        let userId = user.attributes['custom:central_user_id'];
        Constants.userId = userId;
      }
      //console.log(user)
     // const user = await Auth.federatedSignIn(data.get("email"), data.get("password"))
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setNewPasswordRequired(true);
        return;
      }
    } catch (error) {
      setSignInError(error.message);
    } finally {
      setSigningIn(false);
    }
  }

  async function signInWithNewPassword(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setSigningIn(true);
      const user = await Auth.signIn(data.get("email"), password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(
          user,
          newUserAuthToken
        );
      }
    } catch (error) {
      setSignInError(error.message);
    } finally {
      setSigningIn(false);
    }
  }

  const handleResetPassword = () => {
    setIsResetPassword(true)
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setUserEmail(data.get("sendcode"));
      await Auth.forgotPassword(data.get("sendcode"));
      setCodeSent(true);
    } catch (error) {
      setForgotPasswordSendCodeError(error.message)
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPasswordCode = data.get("newPassword");
    const newPasswordConfirmCode = data.get("confirmPassword")
    if (newPasswordCode === newPasswordConfirmCode) {
      try {
        await Auth.forgotPasswordSubmit(
          userEmail,
          data.get("code"),
          data.get("newPassword")
        );
        setForgotSuccessMsg("Password Updated Successfully")
        setTimeout(function () {
          setIsResetPassword(false)
          setForgotSuccessMsg("")
        }, 3000);
      } catch (error) {
        setForgotPasswordSendCodeConfirmError(error.message)
      }
    } else {
      setForgotPasswordSendCodeConfirmError("Password & confirm password should be same")
    }
  }

  const handleBackToSignIn = () => {
    setIsResetPassword(false);
    setForgotPasswordSendCodeError("");
  }

  function renderRequestCodeForm() {
    return (
      <Box
        component="form"
        onSubmit={handleSendCodeClick}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <img src={AppLogo} alt="logo" width="200" />
        </Box>
        {forgotPasswordSendCodeError && (
          <Alert variant="filled" severity="error">
            {forgotPasswordSendCodeError}
          </Alert>
        )}
        <TextField
          onBlur={(event) => {
            handleFormInputChange(
              event.target.value,
              setSendCodeValue,
              "sendcode"
            );
          }}
          margin="normal"
          required
          fullWidth
          id="sendcode"
          label="Enter your Username or Email Address"
          name="sendcode"
          autoComplete="off"
          autoFocus
          onChange={(event) => {
            handleFormInputChange(
              event.target.value,
              setSendCodeValue,
              "sendcode"
            );
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, fontSize: "15px" }}
          disabled={!sendCodeValue}
        >
          Send Code
        </Button>

        <Grid
          container
          sx={{
            marginBottom: "50px",
          }}
        >
          <Grid item xs>
            <Link
              variant="body2"
              onClick={handleBackToSignIn}
              style={{ cursor: "pointer" }}
            >
              Back to Sign In
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="#"
              variant="body2"
              onClick={() =>
                Auth.federatedSignIn({ provider: "rpcidentityprovider" })
              }
            >
              {"Rehrig employee? Click here"}
            </Link>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={RehrigLogo} alt="rehrig-logo" />
        </Box>
      </Box>
    )
  }

  function renderConfirmationForm() {
    return (
      <Box
        component="form"
        onSubmit={handleConfirmClick}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <img src={AppLogo} alt="logo" width="200" />
        </Box>
        {forgotPasswordSendCodeConfirmError && (
          <Alert variant="filled" severity="error">
            {forgotPasswordSendCodeConfirmError}
          </Alert>
        )}
        {forgotSuccessMsg && (
          <Alert variant="filled" severity="success">
            {forgotSuccessMsg}
          </Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="code"
          label="Code"
          name="code"
          autoComplete="off"
          autoFocus
          onChange={(event) => {
            handleFormInputChange(
              event.target.value,
              setConfirmCode,
              "confirmCode"
            );
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          type={showNewPassword ? "text" : "password"}
          id="newPassword"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                >
                  {showNewPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            handleFormInputChange(
              event.target.value,
              setNewPassword,
              "newPassword"
            );
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          id="confirmPassword"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            handleFormInputChange(
              event.target.value,
              setConfirmPassword,
              "confirmPassword"
            );
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, fontSize: "15px" }}
          disabled={!confirmCode || !newUserAuthToken || !confirmPassword}
        >
          Submit
        </Button>
        <Grid
          container
          sx={{
            marginBottom: "50px",
          }}
        >
          <Grid item xs>
          </Grid>
          <Grid item>
            <Link
              href="#"
              variant="body2"
              onClick={() =>
                Auth.federatedSignIn({ provider: "rpcidentityprovider" })
              }
            >
              {"Rehrig employee? Click here"}
            </Link>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={RehrigLogo} alt="rehrig-logo" />
        </Box>
      </Box>
    )
  }

  function renderLoginForm() {
    return (
      <Box
        component="form"
        onSubmit={newPasswordRequired ? signInWithNewPassword : handleSubmit}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <img src={AppLogo} alt="logo" width="200" />
        </Box>
        {signInError && (
          <Alert variant="filled" severity="error">
            {signInError}
          </Alert>
        )}
        <TextField
          onBlur={handleOnBlur}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Username or Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(event) => {
            handleFormInputChange(event.target.value, setEmail, "email");
          }}
          error={emailError}
          helperText={
            emailError ? "Please enter your email address or username" : " "
          }
        />
        {newPasswordRequired ? (
          <>
            <TextField
              value={newUserAuthToken}
              margin="normal"
              required
              fullWidth
              name="newpassword"
              label="Enter your new password"
              type={showPassword ? "text" : "password"}
              id="newpassword"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                handleFormInputChange(
                  event.target.value,
                  setNewPassword,
                  "password"
                );
              }}
            />
            <FormControlLabel
              control={<Radio checked={pwChar !== ""} />}
              label="At least 12 characters"
            />
            <FormControlLabel
              control={<Radio checked={pwCap !== ""} />}
              label="At least 1 capital letter"
            />
            <FormControlLabel
              control={<Radio checked={pwNum !== ""} />}
              label="At least 1 number"
            />
            <FormControlLabel
              control={<Radio checked={pwsc !== ""} />}
              label="At least 1 special character"
            />
            <FormControlLabel
              control={<Radio checked={pwll !== ""} />}
              label="At least 1 lowercase letter"
            />
          </>
        ) : (
          <>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                handleFormInputChange(
                  event.target.value,
                  setPassword,
                  "password"
                );
              }}
            />
          </>
        )}
        {signingIn ? (
          <LoadingButton loading variant="contained">
            Submit
          </LoadingButton>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, fontSize: "15px" }}
            disabled={
              newPasswordRequired ? buttonDisabled : !email || !password
            }
          >
            Sign In
          </Button>
        )}

        <Grid
          container
          sx={{
            marginBottom: "50px",
          }}
        >
          <Grid item xs>
            <Link variant="body2" onClick={() => handleResetPassword()} style={{ cursor: "pointer" }}>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="#"
              variant="body2"
              onClick={() =>
                Auth.federatedSignIn({ provider: "rpcidentityprovider" })
              }
            >
              {"Rehrig employee? Click here"}
            </Link>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={RehrigLogo} alt="rehrig-logo" />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        marginY: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {isResetPassword ?
        codeSent ?
          renderConfirmationForm()
          :
          renderRequestCodeForm()
        :
        renderLoginForm()
      }
    </Box>
  );
}
