const aws = {
    Auth: {
        region: process.env.REACT_APP_REGION, // REQUIRED - Amazon Cognito Region
        identityPoolRegion: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID for authenticated user access
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID for authenticated user access
        oauth: {
            domain: process.env.REACT_APP_AUTHORITY,
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
            redirectSignIn: process.env.REACT_APP_REDIRECT_URI,
            redirectSignOut: process.env.REACT_APP_REDIRECT_URI,
            responseType: "code"
          }
    },
};
// console.log("REACT_APP_USER_POOL_ID", process.env.REACT_APP_USER_POOL_ID);
// console.log("REACT_APP_USER_POOL_CLIENT_ID", process.env.REACT_APP_USER_POOL_CLIENT_ID);
export default aws;